div.App {

    /* border: 2px solid red; */

}



div.tab-menu {
    /* border: 1px solid green; */
}


div.content-view {

    /* border: 1px solid blue; */

    display: flex;
    flex-flow: row;
    margin: 20px;



}



div.content-view div.stage {
    /* border: 1px dashed red; */
    flex: 1;
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px dashed rgba(0,0,0,0.2);
}

div.content-view div.stage:first-child {
    border: none;
    padding-left: 0px;
}

div.content-view div.stage:first-child {
    margin-left: 0px;
}

div.content-view div.stage div.label {
    padding: 0px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 50px;
    min-height:50px;
    max-height: 50px;
    background-color: #F0F0F0;
    margin-bottom: 40px;
    border-radius: 5px;
}


div.content-view div.stage div.content {
    position: relative;
}

div.content-view div.stage div.content div.band {
    position: absolute;
    width: 100%;
    background-color: #ABC;
    color: rgba(0,0,0,0.8);
    font-weight: bold;
    padding: 0px;
    border-radius: 5px;
    box-sizing: border-box;
}

div.content-view div.stage div.content div.band a {
    color: rgba(0,0,0,0.8);
    text-decoration : none;
}






div.content-view {

}